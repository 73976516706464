import React from 'react'
import { Col, Row, Typography } from 'antd'
import { TranslationResult } from './translateApi/translate'

import './GoogleBackendTranslatedText.css'

interface TranslatedTextProps {
    isLoading: boolean;
    isFailed: boolean;
    translationResult: TranslationResult | undefined;
} 

export default function GoogleBackendTranslatedText (props: TranslatedTextProps) {
    const sentencesMarkup = props.translationResult?.sentences.map((sentence, i) => {
        return (
            <Typography.Text key={i} className="TranslatedText TranslatedSentence">{sentence.trans}</Typography.Text>
        )
    })

    return (
        <Row className="TranslatedText">
            <Col xs={24}>
                <Typography.Paragraph className="Paragraph">
                    {sentencesMarkup}
                    {getStatusIndicator(props.translationResult, props.isLoading)}
                </Typography.Paragraph>
            </Col>
        </Row>
    )
}

function getStatusIndicator (translationResult: TranslationResult | undefined, isLoading: boolean) {
    const ellipsis = (<Typography.Text className="Status StatusEllipsis">...</Typography.Text>)
 
    if (translationResult) {
        return null;
    }

    if (translationResult && isLoading) {
        return ellipsis
    }

    if (isLoading) {
        return (
            <Typography.Text className="Status StatusText">Translating{ellipsis}</Typography.Text>
        )
    }

    return (
        <Typography.Text className="Status StatusText">Translation</Typography.Text>
    )
}
