import { GOOGLE_TRANSLATE_RESTRICTED_API_KEY } from './config'

export interface GetSupportedLanguagesResponseLanguage {
    language: string;
    name: string;
}

export interface GetSupportedLanguagesResponseList {
    languages: Array<GetSupportedLanguagesResponseLanguage>
}

const baseURL = 'https://translation.googleapis.com/language/translate/v2/languages'

export default async function getSupportedLanguages (): Promise<{ data: GetSupportedLanguagesResponseList }> {
    const url = `${baseURL}?key=${GOOGLE_TRANSLATE_RESTRICTED_API_KEY}&target=en`
    const response = await fetch(url)
    return response.json()
}