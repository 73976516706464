import { Row, Col, Typography } from "antd";
import { GithubFilled } from '@ant-design/icons'

import './Footer.css'
import React from "react";

export default function Footer () {
    return <div className="Footer">
        
        <Row className="FooterIcons">
            <Col xs={24} style={{textAlign: 'center'}}>
                <a href="https://github.com/abdulhannanali">
                    <GithubFilled twoToneColor="black" size={24}></GithubFilled>
                    <Typography.Title level={3}>Source Code</Typography.Title>
                </a>
            </Col>
        </Row>
        <Row className="Credits">
            <Col xs={24}>
                <Typography.Title level={4} className="TopCoderCredit">made as part of a 
                <a href="https://topcoder.com"> TopCoder </a> 
                Practice Challenge.</Typography.Title>
            </Col>
        </Row>
    </div>
}