import React from 'react';
import { Typography, Row, Col } from 'antd'

export default function Header () {
    return (
        <Row style={{textAlign: 'center'}}>
            <Col xs={24}>
                <a href={"/"}>
                    <Typography.Title level={2} style={{ color: 'black', marginTop: 10 }}>Tarjama!</Typography.Title>
                </a>
            </Col>
        </Row>
    )
} 