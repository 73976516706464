import { useQuery } from 'react-query'
import { QUERIES } from '../constants'
import { translate } from '../translateApi';

export function useTranslateQuery (sourceLanguage?: string, targetLanguage?: string, text?: string) {
    return useQuery([
        QUERIES.GET_TRANSLATION,
        sourceLanguage,
        targetLanguage,
        text
    ], function () {
        if (!(sourceLanguage && targetLanguage && text)) {
            return undefined;
        }

        return translate(sourceLanguage, targetLanguage, text)
    })
}