import React from 'react';
import './App.css'


import {
  QueryClient,
  QueryClientProvider
} from 'react-query'
 
import 'antd/dist/antd.css'
import { Layout } from 'antd';
import Header from './Header';
import { Content } from 'antd/lib/layout/layout';
import TranslationApp from './TranslationComponent';
import Footer from './Footer';

const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Layout className="MainLayout" title="TarjamaApp">
        <Header />
        <Content>
          <TranslationApp />
        </Content>
        <Footer />
      </Layout>
    </QueryClientProvider>
  );
}

export default App;
