import { Row, Col, Layout, Divider } from 'antd'
import { useState } from 'react'
import InputTextAreaWithLimit from './InputTextArea'
import LanguageSelectPane from './LanguageSelectPane'
import { useTranslateQuery } from './queries'
import TranslatedText from './GoogleBackendTranslatedText'

import './TranslationLayout.css'

export default function TranslationApp () {
    const [text, setText] = useState<string | undefined>(undefined)
    const [sourceLanguage, setSourceLanguage] = useState<string | undefined>(undefined)
    const [targetLanguage, setTargetLanguage] = useState<string | undefined>(undefined)

    const translationQuery = useTranslateQuery(
        sourceLanguage,
        targetLanguage,
        text
    )

    function onLanguageChange (sourceLanguage: string, targetLanguage: string) {
        setSourceLanguage(sourceLanguage)
        setTargetLanguage(targetLanguage)
    }

    function onTextChange (text?: string) {
        setText(text)
    }
    
    return (
        <Layout className="TranslationLayout">
            <Row className="SelectPaneContainer">
                <Col xs={24}>
                    <LanguageSelectPane onChange={onLanguageChange} />
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <InputTextAreaWithLimit
                        onChange={onTextChange}
                        totalTextLimit={5000}
                    />
                </Col>
                <Col xs={12}>
                    <TranslatedText 
                        translationResult={translationQuery.data}
                        isLoading={translationQuery.isLoading} 
                        isFailed={translationQuery.isError}
                    />
                </Col>
            </Row>
        </Layout>
    )


}