import { TRANSLATE_ENDPOINT } from "./config";

interface TranslationEngineDebugInfo {
    model_tracking: {
        checkpoint_md5: string;
        launch_doc: string;
    }
}

interface SentenceTranslationResult {
    backend: number;
    orig: string;
    trans: string;
    model_specification?: any[];
    translation_engine_debug_info: TranslationEngineDebugInfo[];
}

export interface TranslationResult {
    confidence: number;

    ld_result: {
        extended_srclangs: string[];
        srclangs: string[];
        srclangs_confidences: number[];
    };

    sentences: SentenceTranslationResult[];
    spell?: {};
    src: string;
}


export default async function translate (sourceLanguage: string, targetLanguage: string, text: string): Promise<TranslationResult> {
    const urlSearchParams = new URLSearchParams()
    
    urlSearchParams.append('sl', sourceLanguage)
    urlSearchParams.append('tl', targetLanguage)
    urlSearchParams.append('text', text)
    
    const url = `${TRANSLATE_ENDPOINT}?${urlSearchParams.toString()}`

    const response = await fetch(url, { method: 'GET' })
    return await response.json() as TranslationResult
}