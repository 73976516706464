import React, { useEffect, useState } from 'react'

import { Col, Row, Typography } from "antd";
import { useQuery } from "react-query";
import { QUERIES } from "./constants";
import { LanguageSelect } from "./LanguageSelect";
import { getSupportedLanguages } from "./translateApi";

import { RightCircleTwoTone } from '@ant-design/icons' 

import './LanguageSelectPane.css'

interface LanguageSelectPaneProps {
    onChange: (sourceLanguage: string, targetLanguage: string) => void
}

export default function LanguageSelectPane (props: LanguageSelectPaneProps) {
    const { data, isLoading, isError } = useQuery(QUERIES.GET_SUPPORTED_LANGUAGES, getSupportedLanguages, {
        refetchInterval: 120 * 60 * 1000
    })

    const [ sourceLanguage, setSourceLanguage ] = useState<undefined | string>()
    const [ targetLanguage, setTargetLanguage ] = useState<undefined | string>()


    useEffect(() => {
        if (typeof sourceLanguage === 'string' && typeof targetLanguage === 'string') {
            requestAnimationFrame(() => props.onChange(sourceLanguage, targetLanguage))
        }
    }, [sourceLanguage, targetLanguage])


    function onSourceChange (language: string) {
        setSourceLanguage(language)
    }

    function onTargetChange (language: string) {
        setTargetLanguage(language)
    }

    const list = data?.data

    return (
        <Row className="LanguageSelectPane">
            <Col xs={11}>
                <LanguageSelect
                    currentValue={sourceLanguage}
                    isLoading={isLoading}
                    isError={isError}
                    getSupportedLanguagesResponseList={list}
                    onChange={onSourceChange}
                /> 
            </Col>
            <Col xs={2} className="RightCircleIcon">
                <RightCircleTwoTone twoToneColor="grey" />
            </Col>
            <Col xs={11}>
                <LanguageSelect
                    currentValue={targetLanguage}
                    isLoading={isLoading}
                    isError={isError}
                    getSupportedLanguagesResponseList={list}
                    onChange={onTargetChange}
                />
            </Col>
        </Row>
    )
}