import React from 'react';
import { Select } from 'antd'
import { GetSupportedLanguagesResponseList } from "./translateApi/getSupportedLanguages";


const { Option } = Select 

interface LanguageSelectProps {
    currentValue?: string;
    getSupportedLanguagesResponseList?: GetSupportedLanguagesResponseList;
    isLoading: boolean;
    isError: boolean;
    onChange: (lang: string) => void
}

export function LanguageSelect (props: LanguageSelectProps) {
    const languages = props.getSupportedLanguagesResponseList?.languages || []

    return (
        <Select
            size="large"
            placeholder={getPlaceholderText(props)}
            value={props.currentValue}
            onChange={props.onChange}
            >
                <React.Fragment>
                    {
                        languages.map(language => <Option key={language.language} value={language.language}>{language.name}</Option>)
                    }
                </React.Fragment>
        </Select>
    )
}


function getPlaceholderText(props: LanguageSelectProps) {
    if (props.isLoading) {
        return 'Loading...'
    }

    if (props.isError) {
        return 'Error occured'
    }

    return 'Select Language...'
}