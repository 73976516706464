import { Col, Input, Row } from 'antd'
import Text from 'antd/lib/typography/Text'
import { useEffect, useState } from 'react'

import './InputTextArea.css'

const { TextArea } = Input

interface InputTextAreaProps {
    onChange: (text: string | undefined) => void,
    totalTextLimit: number;
}


export default function InputTextAreaWithLimit(props: InputTextAreaProps) {
    const [text, setText] = useState<string | undefined>(undefined)
    useEffect(() => {
        requestAnimationFrame(() => {
            props.onChange(text)
        })
    }, [text])
    const currentLength = typeof text === 'string' ? text.length : 0

    return (
        <Row className="InputTextAreaWithLimit">
            <Col xs={24}>
                <TextArea
                    maxLength={props.totalTextLimit}
                    onChange={
                        (event) => {
                            requestAnimationFrame(() => {
                                setText(event.currentTarget.value)
                            })
                        }
                    }
                    autoSize={true}
                    placeholder="enter your text here..."
                ></TextArea>
            </Col>
            <Col xs={24} className="TextLimit">
                <Text type="secondary">
                    {currentLength}/{props.totalTextLimit}
                </Text>
            </Col>
        </Row>

    )   
}